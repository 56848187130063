export const alpha = {
  field: 'alpha',
  label: 'A-Z',
};

export const baseVsAverage = {
  field: '-base-vs-average',
  group: 'snow-report',
  isAllAccess: true,
  label: 'Base vs. Avg',
  mapField: 'sort_base-vs-average',
};

export const closest = {
  field: 'nearby',
  label: 'Closest',
};

export const coldest = {
  field: '+temp-current',
  label: 'Coldest',
};

export const percentOpen = {
  field: '-percent-open',
  group: 'snow-report',
  label: 'Area Open',
  mapField: 'sort_percent-open',
};

export const snowDepth = {
  field: '-snow-depth',
  group: 'snow-report',
  label: 'Base Depth',
  mapField: 'sort_snow-depth',
};

export const snowForecast1d = {
  days: 1,
  field: '-snow-forecast-1d',
  group: 'forecast',
  isAllAccess: true,
  label: 'Next 1 Day',
  mapField: 'sort_snow-forecast-1d',
};

export const snowForecast5d = {
  days: 5,
  field: '-snow-forecast-5d',
  group: 'forecast',
  label: 'Next 1-5 Days',
  mapField: 'sort_snow-forecast-5d',
};

export const snowForecast6to10d = {
  field: '-snow-forecast-6-10d',
  group: 'forecast',
  isAllAccess: true,
  label: 'Next 6-10 Days',
  mapField: 'sort_snow-forecast-6-10d',
};

export const snowForecast10d = {
  days: 10,
  field: '-snow-forecast-10d',
  group: 'forecast',
  isAllAccess: true,
  label: 'Next 10 Days',
  mapField: 'sort_snow-forecast-10d',
};

export const snowHistory1d = {
  days: 1,
  field: '-snow-history-1d',
  group: 'history',
  label: 'Last 24 Hours',
  mapField: 'sort_snow-history-1d',
};

export const snowHistory5d = {
  days: 5,
  field: '-snow-history-5d',
  group: 'history',
  isAllAccess: true,
  label: 'Prev 1-5 Days',
  mapField: 'sort_snow-history-5d',
};

export const snowHistory6to10d = {
  field: '-snow-history-6-10d',
  group: 'history',
  isAllAccess: true,
  label: 'Prev 6-10 Days',
  mapField: 'sort_snow-history-6-10d',
};

export const snowHistory10d = {
  days: 10,
  field: '-snow-history-10d',
  group: 'history',
  isAllAccess: true,
  label: 'Prev 10 Days',
  mapField: 'sort_snow-history-10d',
};

export const snowHistorySeasonal = {
  field: '-snow-history-seasonal',
  group: 'snow-report',
  isAllAccess: true,
  label: 'Season Snowfall',
  mapField: 'sort_snow-history-seasonal',
};

export const tempCurrent = {
  field: 'temp-current',
  group: 'temperature',
  label: 'Current Temp',
  mapField: 'sort_temp-current',
};

export const user = {
  field: 'user',
  label: 'Your Order',
};

export const warmest = {
  field: '-temp-current',
  label: 'Warmest',
};
